$(function(){

	SocialShareKit.init();
	smoothScroll.init({    
		speed: 1000, 
		offset: 100, 
		updateURL: false,
	});

	jQuery('#menu').slicknav();

	var $menu = $("#menuF");

	$(window).scroll(function(){

		/*if($("#counters").isInScene({desfase: 200})){
			//console.log("counters on");
			counters();
		}else{
			//console.log("counters off");
		}
*/
		if ( $(this).scrollTop() > 100 && $menu.hasClass("default") ){
			$menu.fadeOut('fast',function(){
				$(this).removeClass("default")
				.addClass("fixed transbg")
				.fadeIn('fast');
			});
		} else if($(this).scrollTop() <= 100 && $menu.hasClass("fixed")) {
			$menu.fadeOut('fast',function(){
				$(this).removeClass("fixed transbg")
				.addClass("default")
				.fadeIn('fast');
			});
		}
	});
	/*SCRIPT QUE CAMBIA LOS COLORES DE LOS CORAZONES*/
	$('body').on('submit', '#form-contacto', function(event) {
		event.preventDefault();
		var data = $(this).serialize();
		console.log('los datos', data);

		sendMail($(this), 
        function(data){
          console.log('respuesta ajax', data);
          $(".alert").addClass('hidden');         
          $(".alert.alert-success").removeClass('hidden');
          //window.location = $('body').data('redirect');
        },
        function(data){
          $(".alert").addClass('hidden');
          $(".alert.alert-danger").removeClass('hidden');
          errors = data.responseJSON;     
          $.each( errors , function( key, value ) {
            console.log('Errores', value[0]);
          });
        }
        );
		
		
	});

	
	
	/*=======================================*/
	calculateScroll();
	$(window).scroll(function(event) {
		calculateScroll();
	});
	$('body').on('click', '.navmenu ul li a', function() {  
		$('html, body').animate({scrollTop: $(this.hash).offset().top - 80}, 800);
		return false;
	});
	/*=======================================*/

	/*function calculateScroll() {
		var contentTop      =   [];
		var contentBottom   =   [];
		var winTop      =   $(window).scrollTop();
		var rangeTop    =   200;
		var rangeBottom =   500;
		$('.navmenu').find('a').each(function(){
			console.log('ligas', $(this).attr('href'));
			contentTop.push( $( $(this).attr('href') ).offset().top );
			contentBottom.push( $( $(this).attr('href') ).offset().top + $( $(this).attr('href') ).height() );
		})
		$.each( contentTop, function(i){
			if ( winTop > contentTop[i] - rangeTop && winTop < contentBottom[i] - rangeBottom ){
				$('.navmenu li')
				.removeClass('active')
				.eq(i).addClass('active');				
			}
		})
	};*/


	function sdf_FTS(_number,_decimal,_separator)
	{
		var decimal=(typeof(_decimal)!='undefined')?_decimal:2;
		var separator=(typeof(_separator)!='undefined')?_separator:'';
		var r=parseFloat(_number)
		var exp10=Math.pow(10,decimal);
		r=Math.round(r*exp10)/exp10;
		rr=Number(r).toFixed(decimal).toString().split('.');
		b=rr[0].replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g,"\$1"+separator);
		r=(rr[1]?b+'.'+rr[1]:b);

		return r;
	}

	function calculateScroll() {
		var contentTop      =   [];
		var contentBottom   =   [];
		var winTop      =   $(window).scrollTop();
		var rangeTop    =   200;
		var rangeBottom =   500;
		$('.navmenu').find('a').each(function(){
			contentTop.push( $( $(this).attr('href') ).offset().top );
			contentBottom.push( $( $(this).attr('href') ).offset().top + $( $(this).attr('href') ).height() );
		})
		$.each( contentTop, function(i){
			if ( winTop > contentTop[i] - rangeTop && winTop < contentBottom[i] - rangeBottom ){
				$('.navmenu li')
				.removeClass('active')
				.eq(i).addClass('active');				
			}
		})
	};

	/*SCRIPT QUE HACE LA FUNCION DE LOS CONTADORES PREOGRESIVOS*/	
	function counters(){
		setTimeout(function(){
			$('#counter').text('0');
			$('#counter1').text('0');
			$('#counter2').text('0');
			setInterval(function(){

				var curval=parseInt($('#counter').text());
				var curval1=parseInt($('#counter1').text().replace(' ',''));
				var curval2=parseInt($('#counter2').text());
				if(curval<=14999){
					$('#counter').text(curval+1);
				}
				if(curval1<=2999){
					$('#counter1').text(sdf_FTS((curval1+20),0,' '));
				}
				if(curval2<=99){
					$('#counter2').text(curval2+1);
				}
			}, 2);

		}, 1000);
	}

	/*SCRIPT ENVÍA MAIL AJAX*/
	function sendMail(form, fsuccess, ferror){
    //console.log('datos del form',form.serialize());
    //var form = $(this)[0];
    var formData = new FormData(form[0]);
    var route = form.attr('action');

    $.ajax({
      url: route,
      type: 'POST',
      contentType: false,
      processData: false,
      data: formData,
      beforeSend: function(){
        $(".alert").addClass('hidden');
        $(".alert.alert-warning").removeClass('hidden');
        form.find('input[type="submit"]').prop('disabled', true);
      },
      success: fsuccess,
      error: ferror,
      complete: function(){
        console.log('complete ajax');
        form.find('input[type="submit"]').removeAttr('disabled');
        form[0].reset();
      }
    });
    
  }
});




/*Slideshow Background.*/
(function() {
	/*classList | (c) @remy | github.com/remy/polyfills | rem.mit-license.org*/
	!function(){function t(t){this.el=t;for(var n=t.className.replace(/^\s+|\s+$/g,"").split(/\s+/),i=0;i<n.length;i++)e.call(this,n[i])}function n(t,n,i){Object.defineProperty?Object.defineProperty(t,n,{get:i}):t.__defineGetter__(n,i)}if(!("undefined"==typeof window.Element||"classList"in document.documentElement)){var i=Array.prototype,e=i.push,s=i.splice,o=i.join;t.prototype={add:function(t){this.contains(t)||(e.call(this,t),this.el.className=this.toString())},contains:function(t){return-1!=this.el.className.indexOf(t)},item:function(t){return this[t]||null},remove:function(t){if(this.contains(t)){for(var n=0;n<this.length&&this[n]!=t;n++);s.call(this,n,1),this.el.className=this.toString()}},toString:function(){return o.call(this," ")},toggle:function(t){return this.contains(t)?this.remove(t):this.add(t),this.contains(t)}},window.DOMTokenList=t,n(Element.prototype,"classList",function(){return new t(this)})}}();

	/*canUse*/
	window.canUse=function(p){
		if(!window._canUse)
			window._canUse=document.createElement("div");
		var e=window._canUse.style,up=p.charAt(0).toUpperCase()+p.slice(1);
		return p in e||"Moz"+up in e||"Webkit"+up in e||"O"+up in e||"ms"+up in e
	};

	/*window.addEventListener*/
	(function(){
		if("addEventListener"in window)
			return;
		window.addEventListener=function(type,f){
			window.attachEvent("on"+type,f)
		}
	})();

	function slider(bodyName, beforeName, options){
		// Vars.
		var	$body = document.querySelector('.'+bodyName);

		/*Disable animations/transitions until everything's loaded.*/
		$body.classList.add('is-loading');

		window.addEventListener('load', function() {
			window.setTimeout(function() {
				$body.classList.remove('is-loading');
			}, 100);
		});
		/*Settings.*/
		var defaults = {
			/*Images (in the format of 'url': 'alignment').*/
			images: {
				'images/slides/bg01.jpg': 'center',
				'images/slides/bg02.jpg': 'center',
				'images/slides/bg03.jpg': 'center'
			},
			/*Delay.*/
			delay: 6000
		};

		var settings = Object.assign({}, defaults, options);
		console.log('setings', settings);
		/*Vars.*/
		var	pos = 0; 
		var lastPos = 0;
		var $wrapper; 
		var $bgs = []; 
		var $bg;
		var k, v;
		/*Create BG wrapper, BGs.*/
		$wrapper = document.createElement('div');
		$wrapper.id = 'bg';
			//$body.appendChild($wrapper);
			var before = document.getElementById(beforeName);
			$body.insertBefore($wrapper, before);

			//el.insertBefore(elChild, el.firstChild);


			for (k in settings.images) {

				//console.log('Él for', $body, k);
				/*Create BG.*/
				$bg = document.createElement('div');
				$bg.style.backgroundImage = 'url("' + k + '")';
				$bg.style.backgroundPosition = settings.images[k];
				$wrapper.appendChild($bg);
				/*Add it to array.*/
				$bgs.push($bg);
			}

			/*Main loop.*/
			$bgs[pos].classList.add('visible');
			$bgs[pos].classList.add('top');
			/*Bail if we only have a single BG or the client doesn't support transitions.*/
			if ($bgs.length == 1 ||	!canUse('transition'))
				return;
			window.setInterval(function() {
				lastPos = pos;
				pos++;
				/*Wrap to beginning if necessary.*/
				if (pos >= $bgs.length)
					pos = 0;
				/*Swap top images.*/
				$bgs[lastPos].classList.remove('top');
				$bgs[pos].classList.add('visible');
				$bgs[pos].classList.add('top');
				/*Hide last image after a short delay.*/
				window.setTimeout(function() {
					$bgs[lastPos].classList.remove('visible');
				}, settings.delay / 2);
			}, settings.delay);
		}

		slider('headerLine', 'img-addon', {
			images: {
				'images/slides/bg01.jpg': 'center',
				'images/slides/bg02.jpg': 'center',
				'images/slides/bg03.jpg': 'center'
			},
			/*Delay.*/
			delay: 6000

		});

		slider('line4', 'cont-count', {
			images: {
				'images/slides/chart1.png': 'center',
				'images/slides/chart2.png': 'center',
				'images/slides/chart3.png': 'center'
			},
			/*Delay.*/
			delay: 6000
			
		});


})();

		$.fn.extend({	
			isInScene : function(arg)
			{
				//console.log('parametros', arg)
				arg = arg || {};
				arg.desfase = arg.desfase || 0;
				arg.desfase = parseInt(arg.desfase,10);
				var pos_container = $(this).offset().top;
				var container_height = $(this).outerHeight();
				var pos_document = $(document).scrollTop();
				var window_height = $(window).height();	
				return (pos_document+window_height > pos_container+arg.desfase && pos_container+container_height > pos_document+arg.desfase);
			}
		});